import React, { useState } from 'react';
import 'tailwindcss/tailwind.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css'; // Pour inclure la police Poppins
import Slider from 'react-slick';

const experiences = [
  {
    company: "CTG Luxembourg",
    location: "Bertrange (Luxembourg)",
    title: "Software Engineer (Internship)",
    date: "September 2023 - February 2024",
    tasks: [
      "Collaboration with teams of functional analysts and testers",
      "Development of a new application in Vue/Java using agile methodology and CI/CD (Jira/Gitlab)"
    ],
    logo: "/images/logo/ctg.png",
    flag: "/images/flags/luxembourg.png"
  },
  {
    company: "Caisse d’Epargne (CEGEE)",
    location: "Strasbourg (France)",
    title: "Software Engineer (Temporary)",
    date: "July 2023 - August 2023",
    tasks: [
      "Continued development of the application started during internship and deployment to production",
      "Development of new features and bug fixes for existing applications"
    ],
    logo: "/images/logo/caisseepargne.webp",
    flag: "/images/flags/france.png"
  },
  {
    company: "Caisse d’Epargne (CEGEE)",
    location: "Strasbourg (France)",
    title: "Software Engineer (Internship)",
    date: "January 2023 - April 2023",
    tasks: [
      "Project creation and management of an interactive flowchart application using Angular and C# .NET",
      "Development of new features and bug fixes for existing applications"
    ],
    logo: "/images/logo/caisseepargne.webp",
    flag: "/images/flags/france.png"
  },
  {
    company: "MARS WRIGLEY",
    location: "Haguenau (France)",
    title: "Web Developer (Internship)",
    date: "April 2022 - July 2022",
    tasks: [
      "Management and administration of 12 European M&M's websites",
      "Development of new features, tests implementations...",
      "Collaboration with US/India teams"
    ],
    logo: "/images/logo/mars.jpg",
    flag: "/images/flags/france.png"
  }
];

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };

  return (
    <div className="min-h-screen bg-white flex flex-col items-center">
      <nav className="fixed top-0 left-0 w-full bg-white z-10">
        <div className="w-full flex justify-between items-center py-4 px-6 md:px-20">
          <div className="text-2xl font-bold font-poppins">Léandre Fernandez</div>
          <div className="md:hidden">
            <button onClick={toggleMenu}>
              <i className={`fas ${isOpen ? 'fa-times' : 'fa-bars'} text-2xl`}></i>
            </button>
          </div>
          <div className={`${isOpen ? 'block' : 'hidden'} md:flex md:space-x-8 text-lg font-poppins font-bold absolute md:static top-16 left-0 w-full md:w-auto bg-white md:bg-transparent p-4 md:p-0 space-y-4 md:space-y-0`}>
            <a href="#home" className="hover:text-gray-700 block">Home</a>
            <a href="#about" className="hover:text-gray-700 block">About</a>
            <a href="#experience" className="hover:text-gray-700 block">Experience</a>
            <a href="#contact" className="hover:text-gray-700 block">Contact</a>
          </div>
        </div>
      </nav>
      <div id="home" className="flex flex-col items-center justify-center w-full min-h-screen px-4 md:px-20 py-10 md:py-20">
        <div className="w-full max-w-screen-xl">
          <header className="flex flex-col-reverse md:flex-row items-center w-full">
            <div className="md:w-1/2 text-left mt-6 md:mt-0">
              <h1 className="text-5xl font-bold font-poppins">Hello, I'm Léandre Fernandez 👋</h1>
              <p className="text-2xl mt-4 font-poppins">
                A passionate Software Engineer based in Strasbourg, France. <span role="img" aria-label="location">📍</span>
              </p>
              <div className="mt-4 flex">
                <a href="https://www.linkedin.com/in/leandre-fernandez/" className="text-2xl mx-2"><i className="fab fa-linkedin"></i></a>
                <a href="https://github.com/votre-profil" className="text-2xl mx-2"><i className="fab fa-github"></i></a>
              </div>
            </div>
            <div className="hidden md:block md:w-1/2 flex justify-center mt-6 md:mt-0 md:items-center relative">
              <div className="w-90 h-90">
                <img
                  className="w-full h-full"
                  src="https://i.imgur.com/u3riJda.png"
                  alt="Your photo"
                />
              </div>
            </div>
          </header>
          <div className="mt-20 w-full flex items-center">
            <h3 className="text-2xl font-bold text-left">Tech Stack</h3>
            <div className="h-full mx-4 border-l-2 border-gray-400"></div>
            <div className="flex flex-wrap space-x-4 text-left">
              <i className="fab fa-html5 text-3xl md:text-4xl"></i>
              <i className="fab fa-css3-alt text-3xl md:text-4xl"></i>
              <i className="fab fa-js text-3xl md:text-4xl"></i>
              <i className="fab fa-react text-3xl md:text-4xl"></i>
              <i className="fab fa-angular text-3xl md:text-4xl"></i>
              <i className="fab fa-vuejs text-3xl md:text-4xl"></i>
              <i className="fab fa-java text-3xl md:text-4xl"></i>
              <i className="fas fa-database text-3xl md:text-4xl"></i>
              <i className="fab fa-python text-3xl md:text-4xl"></i>
            </div>
          </div>
        </div>
      </div>
      <section id="about" className="w-full flex flex-col md:flex-row items-center max-w-screen-xl py-20 px-4 md:px-16">
        <div className="hidden md:block md:w-1/2 flex justify-center">
          <img
            src="https://i.imgur.com/5rgoh4Y.png"
            alt="About me"
          />
        </div>
        <div className="md:w-1/2 mt-10 md:mt-0 md:pl-10">
          <h2 className="text-xl font-bold text-blue-600">ABOUT ME</h2>
          <h3 className="text-3xl font-bold mt-2">An Aspiring Software Engineer 📍</h3>
          <p className="mt-4 text-lg text-gray-600">
            I studied at CESI Ecole d'Ingénieurs in Lingolsheim, gaining a strong foundation in software engineering. Through internships at Mars, the Caisse d'Epargne, and CTG Luxembourg, I acquired practical skills and knowledge, working on diverse projects and collaborating with talented professionals.
          </p>
        </div>
      </section>
      <section id="experience" className="w-full max-w-screen-xl py-20 px-6 md:px-5">
        <h2 className="text-xl font-bold text-blue-600">EXPERIENCE</h2>
        <Slider {...settings} className="mt-10">
          {experiences.map((exp, index) => (
            <div key={index} className="px-2">
              <div className="bg-white shadow-lg rounded-lg p-6 h-full flex flex-col justify-between">
                <div className="flex items-center">
                  <img src={exp.logo} alt={exp.company} className="w-20 h-20 object-contain mr-4" />
                  <div>
                    <h3 className="text-xl font-bold">{exp.company}</h3>
                    <p className="text-lg text-gray-600 flex items-center">
                      <img src={`${exp.flag}`} alt={exp.location} className="w-6 h-4 mr-2 object-cover" />
                      {exp.location}
                    </p>
                  </div>
                </div>
                <div>
                  <p className="text-lg mt-4 text-gray-800">{exp.title}</p>
                  <p className="text-lg text-gray-600">{exp.date}</p>
                </div>
                <ul className="list-disc list-inside mt-4 text-gray-600 flex-grow">
                  {exp.tasks.map((task, i) => (
                    <li key={i}>{task}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </Slider>
      </section>
      <section id="contact" className="w-full max-w-screen-xl py-20 flex flex-col items-start px-6 md:px-20">
        <h2 className="text-xl font-bold text-blue-600">CONTACT</h2>
        <h3 className="text-3xl font-bold mt-2">Don't be shy! Hit me up! 👇</h3>
        <div className="mt-10 flex flex-wrap space-y-8 md:space-y-0 md:space-x-16">
          <div className="flex items-center w-full md:w-auto">
            <div className="bg-white shadow-md rounded-full p-4">
              <i className="fas fa-phone-alt text-blue-600 text-3xl"></i>
            </div>
            <div className="ml-4 text-left">
              <h4 className="text-lg font-bold">Phone</h4>
              <p className="text-gray-600">+33 7 81 74 28 08</p>
            </div>
          </div>
          <div className="flex items-center w-full md:w-auto">
            <div className="bg-white shadow-md rounded-full p-4">
              <i className="fas fa-envelope text-blue-600 text-3xl"></i>
            </div>
            <div className="ml-4 text-left">
              <h4 className="text-lg font-bold">Mail</h4>
              <p className="text-gray-600">fernandez.leandre@gmail.com</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
